











.animation {
    animation: wiggle 2s;
}

@keyframes wiggle {
    0%,
    7% {
        transform: rotateZ(0);
    }
    15% {
        transform: rotateZ(-20deg);
    }
    20% {
        transform: rotateZ(15deg);
    }
    25% {
        transform: rotateZ(-15deg);
    }
    30% {
        transform: rotateZ(7deg);
    }
    35% {
        transform: rotateZ(-4deg);
    }
    40%,
    100% {
        transform: rotateZ(0);
    }
}
